
<template>
  <div id="data-list-list-view" class="data-list-container">
    <div class="vx-row">
      <div
        class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base"
        v-for="(item,index ) in posts"
        :key="index"
      >
        <!-- {{item}} -->
        <vx-card>
          <div slot="no-body" style="height: 500px;
overflow-x: auto;">
            <div :id="item.post_url">
              <img
                @error="url($event)"
                style="height:300px,width:100%"
                :src="item.post_image_urls"
                alt="Post Doesn’t Exist Anymore"
                class="responsive card-img-top"
              />
            </div>
          </div>
          <div>
            <a :href="item.post_url" class="text-center vx-col instagramButton" target="_blank">
              <p>show post in instagram</p>
            </a>
          </div>

          <h3 class="mb-2 mt-4 text-left">
            Caption :
            <!-- <small @click="showCaption(item.post_image_caption,index)">show like instagram</small> -->
          </h3>
          <p class="text-left Caption" :id="index">{{ item.post_image_caption}}</p>

          <div class="vx-col w-full sm:w-2/2 lg:w-2/2">
            <div class="vx-row mt-base px-3">
              <div class="vx-col w-full sm:w-1/2 md:1/2 lg:w-2/5">
                <div class="vx-row custom-card p-0 shadow pt-2 rounded bg-muted">
                  <div class="vx-col w-full sm:w-1/2 lg:w-1/2 text-left">
                    <span>LIKES</span>
                    <h3 class="mt-3">{{item.number_of_post_likes}}</h3>
                  </div>

                  <div class="vx-col w-full sm:w-1/2 lg:w-1/2 text-success">
                    <span class="material-icons">favorite_border</span>
                  </div>
                </div>
              </div>
              <div class="vx-col w-full sm:w-1/2 lg:w-1/5"></div>
              <div class="vx-col w-full sm:w-1/2 lg:w-2/5">
                <div class="vx-row custom-card p-0 shadow pt-2 rounded bg-muted">
                  <div class="vx-col w-full sm:w-1/2 lg:w-1/2 text-left">
                    <span>COMMENTS</span>
                    <h3 class="mt-3">{{item.number_of_comments}}</h3>
                  </div>
                  <div class="vx-col w-full sm:w-1/2 lg:w-1/2 text-primary">
                    <span class="material-icons">chat_bubble_outline</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-base text-left text-warning">
            <span class="material-icons">access_time</span>
          </div>
          <p class="text-left">
            <strong>Date :</strong>
            {{new Date(item.post_timestamp*1000).toISOString().slice(0, 10).split('/').reverse().join('-')}}
          </p>
          <p class="text-left">
            <strong>Time :</strong>
            {{new Date(item.post_timestamp*1000 ).toLocaleTimeString("en-US")}}
          </p>

          <div class="vx-row text-left mt-4">
            <div class="vx-col w-full sm:w-1/1">
              <p>Profanity : {{Math.round(item.comment_profanities*100)}}%</p>
            </div>
            <div class="vx-col w-full sm:w-1/1 lg:w-1/1 mb-base">
              <vs-progress :height="8" :percent="item.comment_profanities*100" color="danger"></vs-progress>
            </div>
            <div class="vx-col w-full sm:w-1/1 lg:w-1/1 mb-base">
              <div class="vx-col w-full sm:w-1/1">
                <p>Politeness : {{Math.round(Math.abs(item.comment_profanities-1)*100)}}%</p>
              </div>
              <vs-progress
                :height="8"
                :percent="Math.abs(item.comment_profanities-1) *100"
                color="success"
              ></vs-progress>
            </div>
          </div>
          <div class="vx-row text-left">
            <div class="vx-col w-full md:w-1/1">
              <chartjs-bar-chart
                :posetive="item.positive_comment_sentiments"
                :negetive="item.negative_comment_sentiments"
                :neutral="item.neutral_comment_sentiments"
              ></chartjs-bar-chart>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import moduleDataList from "@/store/data-list/moduleDataList.js";
import ChartjsBarChart from "../ChartjsBarChart.vue";

export default {
  data() {
    return {
      id: this.$route.params.username,
      posts: Object
    };
  },
  components: { ChartjsBarChart },
  watch: {
    $route() {
      this.$router.go();
      this.id = this.$route.params.username;
      this.getData();
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http
        .get(`${process.env.VUE_APP_NOT_SECRET_CODE}/instagram/post/${this.id}`)
        .then(res => {
          this.posts = res.data.posts;

          this.getAPost(res.data.posts);
        });
    },
    url(event) {
      event.target.src = "https://mashinno.com/default.png";
    },
    getAPost(data) {
      data.forEach(element => {
        // console.log(element.post_url);
        this.$http.get(element.post_url).then(res => {
          // console.log(res);
          let dom = new DOMParser().parseFromString(res.data, "text/html");
          let head = dom.querySelector("head");
          let metas = head.querySelectorAll(
            "meta[property='og:video:secure_url']"
          );
          // let body = dom.querySelector("body");

          // console.log(body);

          if (metas.length > 0) {
            // console.log(document.getElementById(`${element.post_url}`));
            document.getElementById(
              `${element.post_url}`
            ).innerHTML = `<video poster="${
              element.post_image_urls
            }"  width="100%" height="100%" controls  <source  src="${metas[0].getAttribute(
              "content"
            )}" type="video/mp4"></video>`;
          } else {
            if (file_exists(element.post_image_urls)) {
              document.getElementById(`${element.post_url}`).innerHTML = `
               <img
                
                style="height:300px,width:100%"
                src="${element.post_image_urls}"
                alt="Post Doesn’t Exist Anymore"
                class="responsive card-img-top"
              />
              `;
            } else {
              document.getElementById(`${element.post_url}`).innerHTML = `
              <p>Post Doesn’t Exist Anymore</p>
               <img
                style="height:300px,width:100%"
                src="https://mashinno.com/default.png"
                alt="Post Doesn’t Exist Anymore"
                class="responsive card-img-top"
              />
              `;
            }
          }
        });
      });
      setTimeout(() => {
        this.showCaption();
      }, 1000);
    },
    showCaption() {
      let Cdocument = document.getElementsByClassName("Caption");

      for (let i = 0; i < Cdocument.length; i++) {
        let Pelement = Cdocument[i];

        let index = Pelement.getAttribute("id");
        let dataArray = Pelement.textContent.split(" ");
        let newText = [];
        dataArray.forEach(element => {
          if (element[0] == "#") {
            let pureElemnt = element.substr(1);

            if (pureElemnt[pureElemnt.length - 1] == ".") {
              pureElemnt = pureElemnt.substring(0, pureElemnt.length - 1);
            }
            newText.push(
              `<a href='https://www.instagram.com/explore/tags/${pureElemnt}' target="_blank">${element}</a>`
            );
          } else if (element[0] == "@") {
            let pureElemnt = element.substr(1);
            if (pureElemnt[pureElemnt.length - 1] == ".") {
              pureElemnt = pureElemnt.substring(0, pureElemnt.length - 1);
            }
            if (pureElemnt)
              newText.push(
                `<a href='https://www.instagram.com/${pureElemnt}' target="_blank">${element}</a>`
              );
          } else {
            newText.push(element);
          }
        });
        let text = newText.join(" ");
        let element = document.getElementById(index);
        element.innerHTML = text;
      }
    }
  }
};
</script>

<style scoped>
.Caption {
  height: 200px;
  overflow: auto;
  overflow-x: hidden;
}
.p-0 {
  padding: 0 !important;
}
.custom-card > div {
  padding: 0 30px;
  /* border-right: 1px solid black;
  border-left: 1px solid black; */
  text-align: center;
}
.custom-card {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
  padding: 20px;
  background: white;
  min-height: 30px;
}
.bg-muted {
  padding: 10px 0 8px !important;
  background: rgba(169, 169, 169, 0.188);
}
.instagramButton > p:hover {
  box-shadow: 1px 1rem 2rem #00000030;
  transition: 0.5s;
}
.instagramButton > p {
  transition: 0.5s;
  padding: 3px 0 3px 0;
  /* background: rgba(169, 169, 169, 0.188); */
  background: linear-gradient(
    45deg,
    #405ce615,
    #5851db15,
    #833ab415,
    #c1358415,
    #e1306c15,
    #fd1d1d15
  );
  border: solid 1px rgba(0, 0, 0, 0.404);
  color: rgb(41, 41, 41);
  /* color: linear-gradient(
    45deg,
    #405de691,
    #5851dba6,
    #833ab4b3,
    #c135848a,
    #e1306c91,
    #fd1d1d91 */
  /* ); */
  font-size: 1.3rem;
  border-radius: 10px;
  font-weight: bold;
  box-shadow: 1px 1rem 2rem #00000010;
  margin-bottom: 2rem;
}
</style>

